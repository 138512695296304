import { PlusIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { range } from "lodash";
import AdminCard from "../components/adminCard";
import { API_ADMINS } from "../constants/api";
import Layout from "../layout";
import { getRequest, putRequest } from "../services/axios";
import Pagination from "../components/pagination";
import Header from "../components/header";
import Spinner from "../components/spinner";
import { useNavigate } from "react-router-dom";

interface AdminType {
  _id: string;
  email: string;
  isDeleted: boolean;
  isVerified?: boolean;
}

const ManageAdmins = () => {
  const navigate = useNavigate();
  const [admins, setAdmins] = useState<AdminType[]>([]);
  const [count, setCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalAdmins, setTotalAdmins] = useState<number>(1);
  const [pageNumberList, setPageNumberList] = useState<number[]>([1]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchAdmins();
    if (page === 1)
      setPageNumberList(
        range(
          1,
          Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
        )
      );
    if (page === totalPages) {
      let arg1 = page - 4;
      if (page <= 5) {
        arg1 = 1;
      }
      setPageNumberList([...range(arg1, page + 1)]);
    }
  }, [perPage, page]);

  const fetchAdmins = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const { data } = await getRequest(API_ADMINS, token);
      setAdmins(data?.data);
      setCount(data?.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(count / perPage));
    setTotalAdmins(count);
    setPageNumberList(
      range(
        1,
        Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
      )
    );
  }, [count, perPage]);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    function createNumberArray(n: number) {
      var numbers = [];
      let limit = n;
      if (totalPages - n < 5) {
        limit = totalPages - n + 1;
        for (var i = limit; i < limit + 5; i++) {
          numbers.push(i);
        }
      } else {
        for (var i = n; i < n + 5; i++) {
          numbers.push(i);
        }
      }
      return numbers;
    }
    if (page === totalPages) return;
    if (page === 1) return;
    if (page === pageNumberList[4])
      setPageNumberList([...createNumberArray(page)]);
    if (page === pageNumberList[0] - 1)
      setPageNumberList([...createNumberArray(page - 3)]);
  }, [page, pageNumberList]);

  const handleAdminActivate = async (item: AdminType) => {
    const token = localStorage.getItem("token");
    try {
      const { data } = await putRequest(
        `/${item._id}`,
        { delete: !item.isDeleted },
        token
      );

      if (!data) return;

      const updatedAdmins = await admins?.map((i) => {
        if (i._id === item._id) {
          i.isDeleted = !i.isDeleted;
        }
        return i;
      });
      setAdmins([...updatedAdmins]);

      Swal.fire({
        text: !item.isDeleted ? "User Activated" : "User Deactivated",
        icon: "success",
        showConfirmButton: false,
        timer: 900,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddAdminClick = () => {
    navigate("/add-admin");
  };

  return (
    <>
      <Header pageTitle="Manage Admin" />
      <Layout>
        {loading ? (
          <div className=" flex h-[75vh]  w-full justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="" onClick={handleAddAdminClick}>
              <div className="flex w-full justify-end ">
                <div className="flex  justify-between gap-6 items-center p-2 cursor-pointer shadow-lg bg-blue border text-white border-gray-200 rounded-lg  dark:bg-gray-800 dark:border-gray-700 my-4  w-full md:w-auto">
                  <span className="text-sm">Add Admin</span>
                  <PlusIcon className="h-6 w-6" aria-hidden="true" />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-5">
              {admins &&
                admins.map((item) => (
                  <AdminCard
                    key={item.email}
                    adminItem={item}
                    onRemove={handleAdminActivate}
                  />
                ))}
            </div>
            <Pagination
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              totalPages={totalPages}
              totalResults={totalAdmins}
              pageNumberList={pageNumberList || []}
              perPage={perPage}
            />
          </>
        )}
      </Layout>
    </>
  );
};

export default ManageAdmins;
