import { useEffect, useState } from "react";
import { range } from "lodash";
import { API_ADMINS } from "../constants/api";
import Layout from "../layout";
import AdminForm from "../modules/AdminForm";
import { getRequest } from "../services/axios";
import Header from "../components/header";
import Spinner from "../components/spinner";

interface AdminType {
  _id: string;
  email: string;
  isDeleted: boolean;
  isVerified?: boolean;
}

const AddAdmin = () => {
  const [admins, setAdmins] = useState<AdminType[]>([]);
  const [count, setCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalAdmins, setTotalAdmins] = useState<number>(1);
  const [pageNumberList, setPageNumberList] = useState<number[]>([1]);
  const [loading, setLoading] = useState<boolean>(false);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  // State for editing the profile picture
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    fetchAdmins();
    if (page === 1)
      setPageNumberList(
        range(
          1,
          Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
        )
      );
    if (page === totalPages) {
      let arg1 = page - 4;
      if (page <= 5) {
        arg1 = 1;
      }
      setPageNumberList([...range(arg1, page + 1)]);
    }
  }, [perPage, page]);

  const fetchAdmins = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const { data } = await getRequest(API_ADMINS, token);
      setAdmins(data?.data);
      setCount(data?.count);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(count / perPage));
    setTotalAdmins(count);
    setPageNumberList(
      range(
        1,
        Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
      )
    );
  }, [count, perPage]);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    function createNumberArray(n: number) {
      var numbers = [];
      let limit = n;
      if (totalPages - n < 5) {
        limit = totalPages - n + 1;
        for (var i = limit; i < limit + 5; i++) {
          numbers.push(i);
        }
      } else {
        for (var i = n; i < n + 5; i++) {
          numbers.push(i);
        }
      }
      return numbers;
    }
    if (page === totalPages) return;
    if (page === 1) return;
    if (page === pageNumberList[4])
      setPageNumberList([...createNumberArray(page)]);
    if (page === pageNumberList[0] - 1)
      setPageNumberList([...createNumberArray(page - 3)]);
  }, [page, pageNumberList]);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result as string);
        setEditMode(true);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Header pageTitle="Add Admin" />
      <Layout>
        {loading ? (
          <div className=" flex h-[75vh]  w-full justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="w-full flex justify-center">
              <div className="w-full md:w-1/2 shadow-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-2 md:p-6 mb-4">
                <div className="w-full flex justify-center">
                  <img
                    className="h-24 w-24 rounded-full bg-blueDark"
                    src={
                      profilePicture ||
                      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    }
                    alt=""
                  />
                  <button
                    className="relative"
                    onClick={() =>
                      document.getElementById("fileInput")?.click()
                    }
                  >
                    <div className="absolute right-0 bottom-0 bg-white rounded-full p-2 ml-8">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                      </svg>
                    </div>
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="fileInput"
                    onChange={handleFileInputChange}
                  />
                </div>

                <AdminForm fetchAdmins={fetchAdmins} />
              </div>
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

export default AddAdmin;
