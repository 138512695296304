import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Login from "./pages/login";
import ManageAdmins from "./pages/manage-admins";
import Messages from "./pages/messages";
import ReportedVideos from "./pages/reported-videos";
import Users from "./pages/users";
import Videos from "./pages/videos";
import UserDetail from "./pages/user-detail";
import { Toaster } from "sonner";
import AddAdmin from "./pages/add-admin";

function App() {
  return (
    <Router>
      <Toaster richColors />
      <Routes>
        <Route path="/users" element={<Users />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/reported-videos" element={<ReportedVideos />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/manage-admins" element={<ManageAdmins />} />
        <Route path="/add-admin" element={<AddAdmin />} />
        <Route path="/users/:userId" element={<UserDetail />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
