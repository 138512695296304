import React from "react";

interface Props {
  pageTitle: string;
}

const Header: React.FC<Props> = ({ pageTitle }) => {
  return (
    <header className="hidden container w-auto justify-between lg:flex px-8  items-center p-4 text-black ml-[19rem]  overflow-hidden border-b-2 mr-[1.5rem] border-opacity-80 border-slate-100">
      <h1 className="text-xl font-bold  ">{pageTitle}</h1>
      <button>
        <img
          className="h-8 w-8 rounded-full bg-blueDark"
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
      </button>
    </header>
  );
};

export default Header;
