import { ErrorMessage, Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import { API_ADMINS_CREATE } from "../../constants/api";

import { postRequest } from "../../services/axios";
import { loginValidation } from "../../validations/loginValidation";
import { FormValues } from "./index.type";

interface PropTypes {
  fetchAdmins: () => any;
}

const AdminForm = ({ fetchAdmins }: PropTypes) => {
  const handleSubmit = async (formValues: FormValues) => {
    const token = localStorage.getItem("token");
    try {
      const { data } = await postRequest(API_ADMINS_CREATE, formValues, token);
      console.log("data", data);
      if (!data) return;

      await Swal.fire({
        text: "New Admin Created",
        icon: "success",
        showConfirmButton: false,
        timer: 900,
      });
      fetchAdmins();
    } catch (error: any) {
      console.log("error", error?.response?.data);
    }
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        userName: "",
      }}
      validationSchema={() => loginValidation}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isValid, dirty }) => {
        return (
          <Form className="space-y-4 md:space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                User Name
              </label>
              <Field
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="userName"
                name="UserName"
                placeholder="username"
                error={errors.userName && touched.userName ? true : false}
                helperText={<ErrorMessage name="email" />}
              />
              {errors?.userName && (
                <span className="text-sm text-red-800">{errors?.userName}</span>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Email
              </label>
              <Field
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="email"
                name="email"
                placeholder="email"
                error={errors.email && touched.email ? true : false}
                helperText={<ErrorMessage name="email" />}
              />
              {errors?.email && (
                <span className="text-sm text-red-800">{errors?.email}</span>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Password
              </label>
              <Field
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="password"
                name="password"
                placeholder="password"
                type="password"
                error={errors.password && touched.password ? true : false}
                helperText={<ErrorMessage name="password" />}
              />
              {errors?.password && (
                <span className="text-sm text-red-800">{errors?.password}</span>
              )}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Confirm Password
              </label>
              <Field
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                id="confirm password"
                name="confirm password"
                placeholder="confirm password"
                type="confirmPassword"
                error={
                  errors.confirmPassword && touched.confirmPassword
                    ? true
                    : false
                }
                helperText={<ErrorMessage name="confirmPassword" />}
              />
              {errors?.confirmPassword && (
                <span className="text-sm text-red-800">
                  {errors?.confirmPassword}
                </span>
              )}
            </div>
            <button
              type="submit"
              className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Create
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};
export default AdminForm;
