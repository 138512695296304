export const REQUEST_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const API_URL = process.env.REACT_APP_API_BASE_URL;
export const API_LOGIN = "/login";
export const API_USERS = "/users";
export const API_VIDEOS = "/videos";
// export const API_VIDEOS_REPORT = '/videos/report';
export const API_VIDEOS_REPORT = "/remove-report";
// export const API_MESSAGES = '/contact-support';
export const API_MESSAGES = "/messages";
export const API_ADMINS = "/fetch";
export const API_ADMINS_CREATE = "/create";
