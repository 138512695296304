import React, { useState } from "react";
import Header from "../components/header";
import Layout from "../layout";
import { Switch } from "@headlessui/react";

const UserDetail = () => {
  // Dummy user data for testing
  const dummyUser = {
    name: "John Doe",
    email: "john.doe@example.com",
    phone: "1234567890",
    address: "123 Main St",
    mission: "There are many extra missions...",
    education: "Bachelor in...",
    practice_area: ["Criminal", "Local", "Divorce"],
    type: "Lawyer",
    isActive: true,
    isVerified: true,
    profileImage: "https://via.placeholder.com/150",
  };

  const [isActive, setIsActive] = useState(dummyUser.isActive);
  const [isVerified, setIsVerified] = useState(dummyUser.isVerified);

  return (
    <>
      <Header pageTitle="User Detail" />
      <Layout>
        <div className="max-w-4xl mx-auto mt-8 Shadow-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-2 md:p-6 mb-4">
          <div className="p-6 border-b">
            <div className="flex flex-col sm:flex-row sm:items-center justify-between">
              <div>
                <img
                  src={dummyUser.profileImage}
                  alt="User profile"
                  className="h-20 w-20 rounded-full object-cover mr-4"
                />
                <div className="">
                  <h3 className="text-lg font-semibold py-1 text-gray-900">
                    {dummyUser.name}
                  </h3>
                  <p className="text-gray-500 text-base py-1">
                    {dummyUser.email}
                  </p>
                  <p className="text-gray-500 text-base pb-2">
                    {dummyUser.phone}
                  </p>
                  <p className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-purple-50 text-purple-700 ring-purple-700/10">
                    {dummyUser.type}
                  </p>
                </div>
              </div>
              <div>
                <div className="flex  gap-4 my-4 justify-between sm:justify-end">
                  <p className="inline-flex text-start rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-green-50 text-green-700 ring-green-600/20">
                    Active
                  </p>
                  <Switch
                    checked={isActive}
                    onChange={setIsActive}
                    className={`
                    relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 ${
                      isActive ? "bg-green-400" : "bg-red-500"
                    }`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      className={`pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                        isActive ? "translate-x-5" : "translate-x-0"
                      }`}
                    ></span>
                  </Switch>
                </div>
                <div className="flex justify-between sm:justify-end gap-4">
                  <p className="inline-flex text-start rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-green-50 text-green-700 ring-green-600/20">
                    Verified
                  </p>
                  <Switch
                    checked={isVerified}
                    onChange={setIsVerified}
                    className={`
                    relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 ${
                      isVerified ? "bg-green-400" : "bg-red-500"
                    }`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      className={`pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                        isVerified ? "translate-x-5" : "translate-x-0"
                      }`}
                    ></span>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
          <div className="p-1 sm:p-6 ">
            <div className="flex justify-between  bg-gray-50 p-2 rounded-md mb-2">
              <p className="font-semibold text-base text-gray-900 w-1/2">
                Education
              </p>
              <p className="text-base text-gray-500 text-start w-1/2">
                {dummyUser.education}
              </p>
            </div>
            <div className="flex justify-between bg-gray-50  p-2 rounded-md mb-2">
              <p className="font-semibold text-base text-gray-900 w-1/2">
                Practice Areas
              </p>
              <div className="flex w-1/2 flex-wrap gap-4">
                {dummyUser.practice_area.map((user, index) => (
                  <p
                    key={index}
                    className="inline-flex text-start rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-yellow-50 text-yellow-600 ring-yellow-900/10"
                  >
                    {user}
                  </p>
                ))}
              </div>
            </div>
            <div className="flex justify-between bg-gray-50  p-2 rounded-md mb-2">
              <p className="font-semibold text-base text-gray-900 w-1/2">
                Address
              </p>
              <p className="text-base text-gray-500 text-start w-1/2">
                {dummyUser.address}
              </p>
            </div>
            <div className="flex justify-between bg-gray-50  p-2 rounded-md mb-2">
              <p className="font-semibold text-base text-gray-900 w-1/2">
                Mission
              </p>
              <p className="text-base text-gray-500 text-start w-1/2">
                {dummyUser.mission}
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UserDetail;
