import { Fragment, useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { range } from "lodash";

import { API_USERS } from "../constants/api";
import { getRequest, putRequest } from "../services/axios";
import Layout from "../layout";
import SearchInput from "../components/searchInput";
import SelectMenu from "../components/selectMenu";
import Pagination from "../components/pagination";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../components/spinner";
import { boolean } from "yup";
import Header from "../components/header";

interface UserType {
  id: string;
  email: string;
  name: string;
  isBondsman: boolean;
  isLawyer: boolean;
  isDeleted: boolean;
  profileImage: string;
  isActive: boolean;
}

interface countType {
  totalUsers: number;
  activeUsers: number;
  inActiveUsers: number;
  verifiedUsers: number;
  unVerifiedUsers: number;
  bannedUsers: number;
}

interface OptionsType {
  id: string;
  name: string;
}

enum UserStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Users = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState<UserType[]>();
  const [count, setCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalUsers, setTotalUsers] = useState<number>(1);
  const [pageNumberList, setPageNumberList] = useState<number[]>([1]);
  const [search, setSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userCount, setUserCount] = useState<countType>();
  const [statusRole, setRoleStatus] = useState<string>("all");

  const [status] = useState<UserStatus>(UserStatus.ACTIVE);
  const [rolesData] = useState<OptionsType[]>([
    { id: "all", name: "All" },
    { id: "user", name: "Users" },
    { id: "lawyer", name: "Lawyers" },
    { id: "bondsman", name: "Bondsmen" },
  ]);

  const [selected, setSelected] = useState<OptionsType>(rolesData[0]);

  useEffect(() => {
    setSelected(rolesData[0]);
  }, [rolesData]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) window.location.href = "/";
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getRequest(
          `${API_USERS}?role=${selected.id}&page=${
            page - 1
          }&perpage=${perPage}&search=${search}&${statusRole}=true`,
          token
        );
        setUsers(data?.data);
        setCount(data?.count);
        setUserCount(data);
      } catch (error: any) {
        console.error("Error fetching user:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (page === 1)
      setPageNumberList(
        range(
          1,
          Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
        )
      );
    if (page === totalPages) {
      let arg1 = page - 4;
      if (page <= 5) {
        arg1 = 1;
      }
      setPageNumberList([...range(arg1, page + 1)]);
    }
    fetchData();
  }, [perPage, page, selected, search, totalPages, count, statusRole]);

  useEffect(() => {
    setTotalPages(Math.ceil(count / perPage));
    setTotalUsers(count);
    setPageNumberList(
      range(
        1,
        Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
      )
    );
  }, [count, perPage]);

  useEffect(() => {
    setPage(1);
    console.log("user", users);
  }, [perPage]);

  useEffect(() => {
    setPage(1);
  }, [selected, search]);

  useEffect(() => {
    function createNumberArray(n: number) {
      var numbers = [];
      let limit = n;
      if (totalPages - n < 5) {
        limit = totalPages - n + 1;
        for (var i = limit; i < limit + 5; i++) {
          numbers.push(i);
        }
      } else {
        for (var i = n; i < n + 5; i++) {
          numbers.push(i);
        }
      }
      return numbers;
    }
    if (page === totalPages) return;
    if (page === 1) return;
    if (page === pageNumberList[4])
      setPageNumberList([...createNumberArray(page)]);
    if (page === pageNumberList[0] - 1)
      setPageNumberList([...createNumberArray(page - 3)]);
  }, [page, pageNumberList]);

  const handleDeactivate = async (deactivateUser: UserType) => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await putRequest(
        `${API_USERS}/${deactivateUser.id}`,
        { delete: !deactivateUser?.isDeleted },
        token
      );
      if (data?.message === "success") {
        const updatedUsers = users?.map((user) => {
          if (user.email === deactivateUser.email)
            return { ...user, isDeleted: !user.isDeleted };
          return user;
        });
        setUsers(updatedUsers);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = ({ role }: { role: string }) => {
    setRoleStatus(role);
  };

  return (
    <>
      <Header pageTitle={"Dashboard"} />
      <Layout>
        <Fragment>
          {isLoading && (!users || users.length === 0) ? (
            <div className=" flex h-[75vh]  w-full justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <>
              <>
                <div className="w-full gap-4 my-4 flex border-b-2 border-opacity-80 border-slate-100 items-center">
                  <div className=" flex gap-3 items-center px-3 py-2 text-sm font-medium text-gray-700 relative after:bg-black after:absolute after:-bottom-1 after:left-0 after:h-[0.060rem] after:w-0 after:transition-width after:duration-300 after:ease-in-out after:content-[''] hover:after:w-full ">
                    <button onClick={() => handleSelect({ role: "all" })}>
                      All
                    </button>
                    <p className="bg-gray-700 text-white rounded-md px-2 py-1 group-hover:bg-gray-500 text-xs font-medium my-2">
                      {userCount?.totalUsers}
                    </p>
                  </div>
                  <div className=" flex gap-3 items-center px-3 py-2 text-sm font-medium text-gray-700 relative after:bg-black after:absolute after:-bottom-1 after:left-0 after:h-[0.060rem] after:w-0 after:transition-width after:duration-300 after:ease-in-out after:content-[''] hover:after:w-full">
                    <button onClick={() => handleSelect({ role: "isActive" })}>
                      Active
                    </button>
                    <p className="bg-green-50 text-green-700 rounded-md px-2 py-1 ring-1 ring-inset ring-green-600/20 group-hover:bg-gray-500 text-xs font-medium my-2">
                      {userCount?.activeUsers}
                    </p>
                  </div>
                  <div className=" flex gap-3 items-center px-3 py-2 text-sm font-medium text-gray-700 relative after:bg-black after:absolute after:-bottom-1 after:left-0 after:h-[0.060rem] after:w-0 after:transition-width after:duration-300 after:ease-in-out after:content-[''] hover:after:w-full">
                    <button onClick={() => handleSelect({ role: "inActive" })}>
                      Inactive
                    </button>
                    <p className="bg-rose-100 text-rose-600 ring-green-600/20 ring-1 ring-inset rounded-md px-2 py-1 group-hover:bg-gray-500 text-xs font-medium my-2">
                      {userCount?.inActiveUsers}
                    </p>
                  </div>

                  <div className=" flex gap-3 items-center px-3 py-2 text-sm font-medium text-gray-700 relative after:bg-black after:absolute after:-bottom-1 after:left-0 after:h-[0.060rem] after:w-0 after:transition-width after:duration-300 after:ease-in-out after:content-[''] hover:after:w-full">
                    <button onClick={() => handleSelect({ role: "isBanned" })}>
                      Banned
                    </button>
                    <p className=" rounded-md px-2 py-1 group-hover:bg-gray-500 text-xs font-medium my-2 bg-yellow-50 text-yellow-600 ring-yellow-700/10 ring-1 ring-inset">
                      {userCount?.bannedUsers}
                    </p>
                  </div>
                </div>
                <div className="grid grid-flow-row grid-cols-4 gap-1 place-content-between bg-">
                  <div>
                    <SelectMenu
                      label={"Role"}
                      options={rolesData}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </div>
                  <div className="col-span-3">
                    <SearchInput handleChange={setSearch} />
                  </div>
                </div>
                <table className="min-w-full divide-y divide-gray-300 mb-5">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        User Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {users &&
                      users?.map((user) => (
                        <tr
                          key={user.email}
                          className="even:bg-gray-50 cursor-pointer"
                          onClick={() => {
                            navigate(`/users/${user.id}`);
                          }}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 flex items-center">
                            {user?.profileImage ? (
                              <img
                                src={user.profileImage}
                                alt="user"
                                className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100 mr-2"
                              />
                            ) : (
                              <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100 mr-2">
                                <svg
                                  className="h-full w-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              </span>
                            )}
                            <span className="w-52">{user.name}</span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span
                              className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                                user.isBondsman
                                  ? "bg-rose-100 text-rose-600 ring-green-600/20"
                                  : user.isLawyer
                                  ? "bg-purple-50 text-purple-700 ring-purple-700/10"
                                  : "bg-yellow-50 text-yellow-600 ring-yellow-700/10"
                              }`}
                            >
                              {user.isBondsman
                                ? "Bondsman"
                                : user.isLawyer
                                ? "Lawyer"
                                : "User"}
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span
                              className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                                user.isActive === true
                                  ? "bg-green-50 text-green-700 ring-green-600/20"
                                  : "bg-rose-100 text-rose-600 ring-green-600/20"
                              }`}
                            >
                              {user.isActive === true
                                ? UserStatus.ACTIVE
                                : UserStatus.INACTIVE}
                            </span>
                          </td>
                          {/* Todo required later */}
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <Switch
                              checked={user.isDeleted}
                              onChange={() => handleDeactivate(user)}
                              className={classNames(
                                user.isDeleted
                                  ? "bg-primary-600"
                                  : "bg-gray-200",
                                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
                              )}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                className={classNames(
                                  user.isDeleted
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                )}
                              >
                                <span
                                  className={classNames(
                                    user.isDeleted
                                      ? "opacity-0 duration-100 ease-out"
                                      : "opacity-100 duration-200 ease-in",
                                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                  )}
                                  aria-hidden="true"
                                >
                                  <svg
                                    className="h-3 w-3 text-gray-400"
                                    fill="none"
                                    viewBox="0 0 12 12"
                                  >
                                    <path
                                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span
                                  className={classNames(
                                    user.isDeleted
                                      ? "opacity-100 duration-200 ease-in"
                                      : "opacity-0 duration-100 ease-out",
                                    "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                  )}
                                  aria-hidden="true"
                                >
                                  <svg
                                    className="h-3 w-3 text-primary-600"
                                    fill="currentColor"
                                    viewBox="0 0 12 12"
                                  >
                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                  </svg>
                                </span>
                              </span>
                            </Switch>
                          </td> */}
                          <td className="py-4 pl-4 pr-3  sm:pl-3 flex justify-center items-center">
                            <Link
                              to={`/users/${user.id}`}
                              className="pb-2 relative"
                            >
                              <svg
                                fill="#87ceef"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="800px"
                                height="800px"
                                viewBox="0 0 442.04 442.04"
                                stroke="#87ceef"
                                className="h-6 w-6 transition-transform duration-300 ease-in-out transform hover:scale-110"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />

                                <g id="SVGRepo_iconCarrier">
                                  <g>
                                    <g>
                                      <path d="M221.02,341.304c-49.708,0-103.206-19.44-154.71-56.22C27.808,257.59,4.044,230.351,3.051,229.203 c-4.068-4.697-4.068-11.669,0-16.367c0.993-1.146,24.756-28.387,63.259-55.881c51.505-36.777,105.003-56.219,154.71-56.219 c49.708,0,103.207,19.441,154.71,56.219c38.502,27.494,62.266,54.734,63.259,55.881c4.068,4.697,4.068,11.669,0,16.367 c-0.993,1.146-24.756,28.387-63.259,55.881C324.227,321.863,270.729,341.304,221.02,341.304z M29.638,221.021 c9.61,9.799,27.747,27.03,51.694,44.071c32.83,23.361,83.714,51.212,139.688,51.212s106.859-27.851,139.688-51.212 c23.944-17.038,42.082-34.271,51.694-44.071c-9.609-9.799-27.747-27.03-51.694-44.071 c-32.829-23.362-83.714-51.212-139.688-51.212s-106.858,27.85-139.688,51.212C57.388,193.988,39.25,211.219,29.638,221.021z" />{" "}
                                    </g>
                                    <g>
                                      <path d="M221.02,298.521c-42.734,0-77.5-34.767-77.5-77.5c0-42.733,34.766-77.5,77.5-77.5c18.794,0,36.924,6.814,51.048,19.188 c5.193,4.549,5.715,12.446,1.166,17.639c-4.549,5.193-12.447,5.714-17.639,1.166c-9.564-8.379-21.844-12.993-34.576-12.993 c-28.949,0-52.5,23.552-52.5,52.5s23.551,52.5,52.5,52.5c28.95,0,52.5-23.552,52.5-52.5c0-6.903,5.597-12.5,12.5-12.5 s12.5,5.597,12.5,12.5C298.521,263.754,263.754,298.521,221.02,298.521z" />{" "}
                                    </g>
                                    <g>
                                      <path d="M221.02,246.021c-13.785,0-25-11.215-25-25s11.215-25,25-25c13.786,0,25,11.215,25,25S234.806,246.021,221.02,246.021z" />{" "}
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
              <Pagination
                page={page}
                setPage={setPage}
                setPerPage={setPerPage}
                totalPages={totalPages}
                totalResults={totalUsers}
                pageNumberList={pageNumberList || []}
                perPage={perPage}
              />
            </>
          )}
        </Fragment>
      </Layout>
    </>
  );
};

export default Users;
