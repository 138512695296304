import { Fragment, useEffect, useState } from "react";
import { XCircleIcon, PlayIcon, TrashIcon } from "@heroicons/react/20/solid";
import ReactPlayer from "react-player";
import Swal from "sweetalert2";
import { range } from "lodash";
import { API_VIDEOS, API_VIDEOS_REPORT } from "../constants/api";
import Layout from "../layout";
import { deleteRequest, getRequest, putRequest } from "../services/axios";
import Modal from "../components/modal";
import Pagination from "../components/pagination";

interface ReportedByType {
  _id: string;
  name: string;
  profileImage: string;
  title?: string;
}

interface VideoType {
  _id: string;
  username: string;
  link: string;
  picture: string;
  type: string;
  thumbnail: string;
  reportedBy: ReportedByType[];
  role: string;
}

const ReportedVideos = () => {
  const [videos, setVideos] = useState<VideoType[]>([]);
  const [reportedBy, setReportedBy] = useState<ReportedByType[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalVideos, setTotalVideos] = useState<number>(1);
  const [pageNumberList, setPageNumberList] = useState<number[]>([1]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchVideos = async () => {
      try {
        const { data } = await getRequest(
          `${API_VIDEOS}?reported=true&approved=true`,
          token
        );
        setVideos([...data?.data]);
        setCount(data?.count);
      } catch (error) {
        console.log("error");
      }
    };
    if (page === 1)
      setPageNumberList(
        range(
          1,
          Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
        )
      );
    if (page === totalPages) {
      let arg1 = page - 4;
      if (page <= 5) {
        arg1 = 1;
      }
      setPageNumberList([...range(arg1, page + 1)]);
    }
    fetchVideos();
  }, [perPage, page]);

  useEffect(() => {
    setTotalPages(Math.ceil(count / perPage));
    setTotalVideos(count);
    setPageNumberList(
      range(
        1,
        Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
      )
    );
  }, [count, perPage]);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    function createNumberArray(n: number) {
      var numbers = [];
      let limit = n;
      if (totalPages - n < 5) {
        limit = totalPages - n + 1;
        for (var i = limit; i < limit + 5; i++) {
          numbers.push(i);
        }
      } else {
        for (var i = n; i < n + 5; i++) {
          numbers.push(i);
        }
      }
      return numbers;
    }
    if (page === totalPages) return;
    if (page === 1) return;
    if (page === pageNumberList[4])
      setPageNumberList([...createNumberArray(page)]);
    if (page === pageNumberList[0] - 1)
      setPageNumberList([...createNumberArray(page - 3)]);
  }, [page, pageNumberList]);

  const handleModal = (reporters: any[]) => {
    const updatedReporters = reporters?.map((item) => {
      return {
        _id: item._id,
        name: item.reportedBy.name,
        profileImage: item.reportedBy.profileImage,
        title: item.reportReason.title,
      };
    });
    // setReportedBy([...reporters]);
    setReportedBy([...updatedReporters]);
    setOpen(true);
  };

  const handleVideoDelete = async (id: string) => {
    const token = localStorage.getItem("token");
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure you want to delete the video ?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
    });
    if (isConfirmed) {
      let URL = `${API_VIDEOS}/${id}`;
      try {
        const { data } = await deleteRequest(URL, token);
        if (!data) return;

        const updatedItems = videos.filter((item) => item._id !== id);
        setVideos([...updatedItems]);

        await Swal.fire({
          text: "Video Deleted",
          icon: "success",
          showConfirmButton: false,
          timer: 900,
        });
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const handleVideoRemoveReport = async (id: string) => {
    const token = localStorage.getItem("token");
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure you want to remove report?",
      icon: "question",
      confirmButtonText: "Remove Report",
      showCancelButton: true,
    });
    if (isConfirmed) {
      let URL = `${API_VIDEOS_REPORT}/${id}`;
      try {
        const { data } = await putRequest(URL, { report: "false" }, token);
        if (!data) return;

        const updatedItems = videos.filter((item) => item._id !== id);
        setVideos([...updatedItems]);

        Swal.fire({
          text: "Report Removed",
          icon: "success",
          showConfirmButton: false,
          timer: 900,
        });
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <Layout>
      <Fragment>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-6 mb-5">
          <Modal open={open} setOpen={setOpen}>
            <div>
              <h3 className="font-medium text-gray-900">
                Reported By Following Users
              </h3>
              <ul className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                {reportedBy &&
                  reportedBy.map((item) => (
                    <li className="flex items-center justify-between py-3">
                      <div className="flex items-center">
                        <img
                          src={item.profileImage}
                          alt="reporter"
                          className="h-8 w-8 rounded-full"
                        />
                        <p className="ml-4 text-sm font-medium text-gray-900">
                          {item.name}
                        </p>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </Modal>
          {videos &&
            videos?.map((item) => (
              <div
                key={item.link}
                className="shadow-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
              >
                <div className="px-5 py-2 flex justify-between border-b-2 border-gray-200">
                  <div className="flex items-center">
                    <img
                      className="w-20 h-20 mr-3 rounded-full shadow-lg"
                      src={item.picture}
                      alt="uploader of the video"
                    />
                    <div>
                      <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                        {item.username}
                      </h5>
                      <span className="text-sm text-gray-500 dark:text-gray-400 capitalize">
                        {item.role}
                      </span>
                    </div>
                  </div>
                  <span
                    className="text-sm decoration-1 underline cursor-pointer"
                    onClick={() => handleModal(item?.reportedBy)}
                  >
                    View Reporters
                  </span>
                </div>
                <div className="h-64 flex justify-center">
                  {item.type.includes("video") ? (
                    <div className="h-full flex">
                      <ReactPlayer
                        className="h-full w-full"
                        url={item.link}
                        playIcon={<PlayIcon />}
                        controls={true}
                        config={{
                          file: {
                            attributes: {
                              poster: item?.thumbnail,
                            },
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      className="h-full"
                      src={item.link}
                      alt="uploaded video for approval"
                    />
                  )}
                </div>
                <div className="flex flex-col items-center py-3">
                  <div className="flex space-x-3">
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-primary-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                      onClick={() => handleVideoRemoveReport(item._id)}
                    >
                      Reject Report
                      <XCircleIcon
                        className="-mr-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-900 bg-white border border-gray-300 shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                      onClick={() => handleVideoDelete(item._id)}
                    >
                      Delete
                      <TrashIcon
                        className="-mr-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          totalPages={totalPages}
          totalResults={totalVideos}
          pageNumberList={pageNumberList || []}
          perPage={perPage}
        />
      </Fragment>
    </Layout>
  );
};

export default ReportedVideos;
