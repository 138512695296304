import { Switch } from "@headlessui/react";

interface AdminType {
  _id: string;
  email: string;
  isDeleted: boolean;
  isVerified?: boolean;
}
interface AdminCardPropsType {
  adminItem: AdminType;
  onRemove: (item: AdminType) => any;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const AdminCard = ({ adminItem, onRemove }: AdminCardPropsType) => {
  return (
    <div className="shadow-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-4">
      <div className=" flex w-full justify-between items-center">
        <span
          className={`inline-flex rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
            adminItem.isDeleted === false
              ? "bg-green-50 text-green-700 ring-green-600/20"
              : "bg-[#ff563029] text-[#b71d18] ring-red-600/20"
          }`}
        >
          {adminItem.isDeleted ? "Deactivated" : "Deactivate"}
        </span>
        <Switch.Group as="div" className="flex items-center gap-4">
          <Switch.Label as="span" className="ml-3 text-sm"></Switch.Label>
          <Switch
            checked={adminItem.isDeleted}
            onChange={() => onRemove(adminItem)}
            className={classNames(
              adminItem.isDeleted ? "bg-primary-600" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              className={classNames(
                adminItem.isDeleted ? "translate-x-5" : "translate-x-0",
                "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            >
              <span
                className={classNames(
                  adminItem.isDeleted
                    ? "opacity-0 duration-100 ease-out"
                    : "opacity-100 duration-200 ease-in",
                  "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                )}
                aria-hidden="true"
              >
                <svg
                  className="h-3 w-3 text-gray-400"
                  fill="none"
                  viewBox="0 0 12 12"
                >
                  <path
                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span
                className={classNames(
                  adminItem.isDeleted
                    ? "opacity-100 duration-200 ease-in"
                    : "opacity-0 duration-100 ease-out",
                  "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                )}
                aria-hidden="true"
              >
                <svg
                  className="h-3 w-3 text-primary-600"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </Switch.Group>
      </div>

      <div className="px-5 py-4">
        <div className="flex items-center flex-col">
          <img
            className="h-24 w-24 rounded-full bg-blueDark"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
          <p className="font-semibold text-gray-900 py-2">Jhon Doe</p>
          <span className="text-sm text-gray-700 dark:text-gray-400 pb-2">
            {adminItem.email}
          </span>
          <p
            className={`inline-flex rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
              adminItem.isVerified === true
                ? "bg-green-50 text-green-700 ring-green-600/20"
                : "bg-[#ff563029] text-[#b71d18] ring-red-600/20"
            }`}
          >
            {adminItem.isVerified === true ? "Verified" : "Non Verified"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdminCard;
