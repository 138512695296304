import { Fragment, useEffect, useState } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  ArchiveBoxArrowDownIcon,
} from "@heroicons/react/20/solid";
import Swal from "sweetalert2";
import { range } from "lodash";

import { API_MESSAGES } from "../constants/api";
import { getRequest, putRequest } from "../services/axios";
import Layout from "../layout";
import Modal from "../components/modal";
import Pagination from "../components/pagination";

interface MessageType {
  id: string;
  email: string;
  name: string;
  message: string;
}

const Messages = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [count, setCount] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalMessages, setTotalMessages] = useState<number>(1);
  const [pageNumberList, setPageNumberList] = useState<number[]>([
    1, 2, 3, 4, 5,
  ]);
  const [open, setOpen] = useState<boolean>(false);
  const [messageDetails, setMessageDetails] = useState<MessageType>();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) window.location.href = "/";
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchData = async () => {
      const { data } = await getRequest(
        `${API_MESSAGES}?page=${page - 1}&perpage=${perPage}`,
        token
      );
      setMessages(data?.data);
      setCount(data?.count);
    };
    if (page === 1)
      setPageNumberList(
        range(
          1,
          Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
        )
      );
    if (page === totalPages) {
      let arg1 = page - 4;
      if (page <= 5) {
        arg1 = 1;
      }
      setPageNumberList([...range(arg1, page + 1)]);
    }
    fetchData();
  }, [perPage, page]);

  useEffect(() => {
    setTotalPages(Math.ceil(count / perPage));
    setTotalMessages(count);
    setPageNumberList(
      range(
        1,
        Math.ceil(count / perPage) > 5 ? 6 : Math.ceil(count / perPage) + 1
      )
    );
  }, [count, perPage]);

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    function createNumberArray(n: number) {
      var numbers = [];
      let limit = n;
      if (totalPages - n < 5) {
        limit = totalPages - n + 1;
        for (var i = limit; i < limit + 5; i++) {
          numbers.push(i);
        }
      } else {
        for (var i = n; i < n + 5; i++) {
          numbers.push(i);
        }
      }
      return numbers;
    }
    if (page === totalPages) return;
    if (page === 1) return;
    if (page === pageNumberList[4])
      setPageNumberList([...createNumberArray(page)]);
    if (page === pageNumberList[0] - 1)
      setPageNumberList([...createNumberArray(page - 3)]);
  }, [page, pageNumberList]);

  const handleArchiveMessage = async (id: string) => {
    if (!id) return;
    const token = localStorage.getItem("token");

    let URL = `${API_MESSAGES}/${id}`;

    try {
      const { data } = await putRequest(URL, { archived: true }, token);
      if (!data) return;

      const newCurrentItems = messages.filter((item) => item.id !== id);
      setMessages([...newCurrentItems]);

      await Swal.fire({
        text: "Message Archived",
        icon: "success",
        showConfirmButton: false,
        timer: 900,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowDetails = (item: MessageType) => {
    setMessageDetails(item);
    setOpen(true);
  };

  return (
    <Layout>
      <Fragment>
        <Modal open={open} setOpen={setOpen}>
          <div>
            <h3 className="font-medium text-gray-900">Message Details</h3>
            <div className="mt-3 space-y-2">
              <p className="text-sm text-gray-800 ">
                <span className="font-medium">Name</span>:{" "}
                {messageDetails?.name}
              </p>
              <p className="text-sm text-gray-800 ">
                <span className="font-medium">Email</span>:{" "}
                {messageDetails?.email}
              </p>
              <p className="text-sm text-gray-800 ">
                <span className="font-medium">Message</span>:{" "}
                {messageDetails?.message}
              </p>
            </div>
            <div className="mt-3 text-center">
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-900 bg-white border border-gray-300 shadow-sm hover:bg-blue hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                onClick={() => handleArchiveMessage(messageDetails?.id || "")}
              >
                Archive
                <ArchiveBoxArrowDownIcon
                  className="-mr-0.5 h-5 w-5"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </Modal>
        <table className="min-w-full divide-y divide-gray-300 table-fixed">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-md"
              >
                Message
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Archive
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {messages &&
              messages?.map((message, index) => (
                <tr
                  key={`${index}-${message.email}`}
                  className="even:bg-gray-50 cursor-pointer"
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    {message.name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {message.email}
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate max-w-md"
                    onClick={() => handleShowDetails(message)}
                  >
                    {message.message}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-900 bg-white border border-gray-300 shadow-sm hover:bg-blue hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                      onClick={() => handleArchiveMessage(message.id)}
                    >
                      Archive
                      <ArchiveBoxArrowDownIcon
                        className="-mr-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
          totalPages={totalPages}
          totalResults={totalMessages}
          pageNumberList={pageNumberList || []}
          perPage={perPage}
        />
      </Fragment>
    </Layout>
  );
};

export default Messages;
