import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

interface PropTypes {
  handleChange: (searchText: string) => any;
}
const SearchInput = ({ handleChange }: PropTypes) => {
  return (
    <div className="mb-3">
      <label
        htmlFor="price"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Search
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          id="search"
          className="block w-full rounded-md border-0 py-3 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
          placeholder="Search Users ..."
          onChange={(e) => handleChange(e?.target?.value)}
        />
        {/* <div className="absolute inset-y-0 right-0 flex items-center">
          <select
            name="search"
            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm"
          >
            <option>Name</option>
            <option>Email</option>
          </select>
        </div> */}
      </div>
    </div>
  );
};

export default SearchInput;
