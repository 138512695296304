import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import SelectMenu from "./selectMenu";

interface PaginationPropTypes {
  page: number;
  perPage: number;
  totalPages: number;
  totalResults: number;
  pageNumberList: number[];
  setPage: (args: number) => void;
  setPerPage: (args: number) => void;
  handlePageSizeChange?: (args: any) => void;
}

interface OptionsType {
  id: string;
  name: string;
}

const Pagination = ({
  page,
  setPage,
  perPage,
  totalPages,
  totalResults,
  pageNumberList,
  setPerPage,
}: PaginationPropTypes) => {
  const [pageSizeOptions] = useState<OptionsType[]>([
    { id: "10", name: "10" },
    { id: "20", name: "20" },
    { id: "50", name: "50" },
    { id: "100", name: "100" },
  ]);
  const [selected, setSelected] = useState<OptionsType>(pageSizeOptions[0]);
  const handlePageSizeChange = () => setPerPage(Number(selected.id));

  useEffect(() => {
    handlePageSizeChange();
  }, [selected]);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white py-3 mb-10">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        <button
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {page === 1 ? page : (page - 1) * perPage + 1}
            </span>{" "}
            to{" "}
            <span className="font-medium">
              {page * perPage < totalResults ? page * perPage : totalResults}
            </span>{" "}
            of <span className="font-medium">{totalResults}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => setPage(1)}
              disabled={page === 1}
            >
              <span className="sr-only">First</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageNumberList.map((item) => (
              <button
                aria-current="page"
                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                  page === item
                    ? "z-10 text-white focus:z-20 bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                    : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                }`}
                onClick={() => setPage(item)}
                key={item}
              >
                {item}
              </button>
            ))}
            <button
              className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => setPage(page + 1)}
              disabled={page === totalPages}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => setPage(totalPages)}
              disabled={page === totalPages}
            >
              <span className="sr-only">Last</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
      <SelectMenu
        options={pageSizeOptions}
        selected={selected}
        setSelected={setSelected}
        // setSelected={handlePageSizeChange}
      />
    </div>
  );
};

export default Pagination;
