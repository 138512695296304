import axios, { AxiosRequestConfig } from 'axios';
import { API_URL, REQUEST_HEADERS } from '../constants/api';
import { IRequestBody } from './service.types';

export const getRequest = (url: string | any, token: string | any, config?: AxiosRequestConfig) => {
    return axios.get(API_URL + url, { ...config, headers: {
        ...REQUEST_HEADERS,
        ...config?.headers,
        token: `${token}`
    } } )
}

export const postRequest = (url: string, body: IRequestBody | any, token?: string | any, config?: AxiosRequestConfig) => {
    return axios.post(API_URL + url, body, { ...config, headers: {
        ...REQUEST_HEADERS, 
        ...config?.headers,
        token: `${token}`
    } } )
}

export const putRequest = (url: string, body: IRequestBody | any, token: string | any, config?: AxiosRequestConfig) => {
    return axios.put(API_URL + url, body, { ...config, headers: {
        ...REQUEST_HEADERS, 
        ...config?.headers,
        token: `${token}`
    } } )
}

export const deleteRequest = (url: string, token: string | any, config?: AxiosRequestConfig) => {
    return axios.delete(API_URL + url, { ...config, headers: {
        ...REQUEST_HEADERS, 
        ...config?.headers,
        token: `${token}`
    } } )
}