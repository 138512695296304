import * as yup from "yup";

export const loginValidation = yup.object().shape({
  email: yup.string().email().required("Email is Required"),
  password: yup.string().min(6).required("Password is Required"),
});
export const addAdminValidation = yup.object().shape({
  email: yup.string().email().required("Email is Required"),
  username: yup.string().required("Username is Required"),
  password: yup.string().min(6).required("Password is Required"),
  confirmPassword: yup
    .string()
    .min(6)
    .required(" confirm password is Required"),
});
